<template>
  <main class="container">
    <ContentHeader
      :title="content_header.title"
      :subTitle="content_header.subTitle"
    />

    <div class="d-flex justify-content-between align-items-center row p-5">
      <div
        class="col-4 d-flex flex-column f-border"
        v-for="list in menuList"
        :key="list.id"
        @click="routerLink(list.link)"
      >
        <span
          class="table__header__text table__text text--capital p-3"
          :style="`color: ${list.color} !important`"
        >
          {{ list.header }}
        </span>
        <span class="sidebar__link__subtitle text">
          {{ list.description }}
        </span>
      </div>
      <div
        v-if="menuList.length % 2 !== 0"
        class="col-4 d-flex flex-column"
      ></div>
    </div>
  </main>
</template>

<script>
export default {
  name: "AdministrationMenu",
  data() {
    return {
      content_header: {
        title: "Administration Menu",
        subTitle: `List of features under administration menu.`,
      },
      menuList: [
        {
          id: 1,
          color: "#FF0000",
          link: "/organization-details",
          header: "general setup",
          description: `Set up Core Lending  Management System with all the details for your organization. 
        Define your institution details and contact information, 
        configure your holidays and working days as well as your labels 
        and templates. Customize your internal control settings to prevent 
        errors and fraud and manage your custom fields.`,
        },
        {
          id: 2,
          color: "#0000FF",
          link: "/employers",
          header: "employer setup",
          description: `Set up Core Lending  Management System with all the employers details for your organization.`,
        },
        {
          id: 3,
          color: "#800080",
          link: "/branches",
          header: "organization",
          description: `Structure your organization by creating and editing branches & centres.
        You can then assign customers, groups and users to branches to capture 
        how your organization works.`,
        },
        {
          id: 4,
          color: "#808000",
          link: "/users",
          header: "access",
          description: `Open up Core Lending  Management System to your entire organization by creating and managing your users. 
        Create and edit users or modify permissions for existing ones to give everyone 
        the level of access they need and the rights to work with the system.`,
        },
        {
          id: 5,
          color: "#800000",
          link: "/deposits",
          header: "product",
          description: `Manage the products offerings that you have available to your customers 
        by creating and editing loan and deposit products.`,
        },
        {
          id: 6,
          color: "#00008B",
          link: "/field-customers",
          header: "fields",
          description: `Determine what information needs to be included in the customers profiles, 
        groups, centres, branches, loan, guarantors and savings accounts, 
        in addition to the default fields provided by Zedvance.`,
        },
        {
          id: 7,
          color: "#008000",
          link: "/views",
          header: "views",
          description: `Manage all the custom views in the system, regardless of their visibility rights. 
        Add, delete or update some of the existing ones.`,
        },
        {
          id: 8,
          color: "#D4AF37",
          link: "/notifications",
          header: "notifications",
          description: `Customize your workflows by pushing automated notifications to 
        your local ecosystem when transactions are posted, customers 
        or accounts are created and more.`,
        },
        {
          id: 9,
          color: "#A52A2A",
          link: "/templates",
          header: "templates",
          description: `Standardize the communication with your customers by using templates 
        for manual SMS and email messages and improve your staff workflows by 
        using templates for their tasks.`,
        },
        {
          id: 10,
          color: "#FF00FF",
          link: "/apps",
          header: "apps",
          description: `Integrate  with your local ecosystem. Use APIs to easily 
        incorporate services like credit scoring, ATMs, agent network 
        and mobile money providers or build your own apps to expand 
        the core functionality for your unique needs.`,
        },
        {
          id: 11,
          color: "#FFA500",
          link: "/finance_coa",
          header: "accounting",
          description: `View accounting details for your organization.`,
        },
      ],
    };
  },
  methods: {
    routerLink(link) {
      this.$router.push({ path: link });
    },
  },
};
</script>

<style scoped>
.f-border {
  border: 1px solid #ddd;
  border-radius: 7px;
  height: 180px;
  margin-bottom: 20px;
  cursor: pointer;
}
.text {
  color: #000;
}
</style>
